'use client'

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { EmptyState } from '@b-stock/bstock-react'

export default function NotFound() {
  return (
    <EmptyState
      icon={faExclamationTriangle}
      title="404"
      description="Something went wrong. Please try again later."
    />
  )
}
